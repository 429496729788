@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  background: #000000;
  color: white;
}

/* Fade in animations code - start */
.hidden-element {
  opacity: 0;
  transition: all 1s;
  filter: blur(4px);
}

.animate-fadeIn {
  opacity: 1;
  filter: blur(0);
}

@media (prefers-reduced-motion) {
  .hidden-element {
    transition: none;
  }
}

.delay-image-transition:nth-child(1) {
  transition-delay: 200ms;
}

.delay-image-transition:nth-child(2) {
  transition-delay: 400ms;
}
/* Fade in animations code - end */

/* Text Slider - Start */
.m-scroll {
  display: flex;
  position: relative;
  width: 1200px;
  height: 200px;
  margin: auto;
  overflow: hidden;
  z-index: 1;
}
.m-scroll__title {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  transition: all 1s ease;
}
.m-scroll__title > div {
  display: flex;
  animation: scrollText 33s infinite linear;
}
.m-scroll__title h1 {
  margin: 0;
  font-size: 120px;
  color: #ffffff;
  transition: all 2s ease;
}
.m-scroll__title a {
  text-decoration: none;
  -webkit-text-stroke: 1px white;
  color: transparent;
  font-family: "Roboto Slab", serif;
}

.m-scroll__title a:nth-child(odd) {
  -webkit-text-stroke: 1px #db9304;
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}
/* Text Slider - End */

#cocktail-bg:before {
  background: linear-gradient(#000, transparent);
}

/* Gallery Slider - Start */
.image-gallery-slide {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-gallery-image {
  width: 100%;
  height: 300px;
}
.image-gallery-right-nav {
  display: none;
}
.image-gallery-left-nav {
  display: none;
}
/* Gallery Slider - End */

.react-pdf__Document {
  background: transparent;
  color: transparent;
  border: none;
}
.react-pdf__Page {
  background-color: transparent !important;
  color: transparent;
  border: none;
}
